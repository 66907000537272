<template>
    <div class="data__sheet">
        <h2 class="doctor_name">{{doctor.name}}</h2>
        <div class="data__sheet_container">
            <div class="data__sheet__img" :style="{'background-image': `url(${doctor.image})`}">
            </div>
            <div class="data__sheet__name">
                <h4 class="doctor_speciality">{{doctor.medical_specialty}}</h4>
            </div>
            <div class="data__sheet__block mt30">
                <h3 class="doctor_clinic_name">{{doctor.clinic}}</h3>
                <h4 class="doctor_clinic_location">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
                    <a target="_blank" :href="`https://www.google.es/maps/place/${doctor.lat}+${doctor.lng}`">{{doctor.clinic}}</a>
                </h4>
                <h4 class="doctor_phone">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z"/></svg>
                    <a :href="`tel:${doctor.telephone}`">{{doctor.telephone}}</a>
                </h4>
                <h4 v-if="doctor.mobile" class="doctor_phone">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 2c0-1.104-.896-2-2-2h-10c-1.104 0-2 .896-2 2v20c0 1.104.896 2 2 2h10c1.104 0 2-.896 2-2v-20zm-8.5 0h3c.276 0 .5.224.5.5s-.224.5-.5.5h-3c-.276 0-.5-.224-.5-.5s.224-.5.5-.5zm1.5 20c-.553 0-1-.448-1-1s.447-1 1-1c.552 0 .999.448.999 1s-.447 1-.999 1zm5-3h-10v-14.024h10v14.024z"/></svg>
                    <a :href="`tel:${doctor.mobile}`">{{doctor.mobile}}</a>
                </h4>
                <h4 class="doctor_mail">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 3v18h24v-18h-24zm6.623 7.929l-4.623 5.712v-9.458l4.623 3.746zm-4.141-5.929h19.035l-9.517 7.713-9.518-7.713zm5.694 7.188l3.824 3.099 3.83-3.104 5.612 6.817h-18.779l5.513-6.812zm9.208-1.264l4.616-3.741v9.348l-4.616-5.607z"/></svg>
                    <a :href="`mailto:${doctor.email}`">{{doctor.email}}</a>
                </h4>
                <div class="">{{doctor.description}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Doctor',
    props:{
        doctor: Object
    }
}
</script>