<template>
    <div class="inner height_inner">
        <article class="content_block news">
            <div class="grid grid_wrap grid_pad">
                <div class="grid_col" v-for="(noticia, index) in noticias" :key="index">
                    <div class="news_thumb">
                        <router-link :to="`/noticias/${noticia.url}`" class="news_link" :style="{'background-image': `url(${noticia.image})`}"></router-link>
                        <div class="news_thumb_text">
                            <h5 class="news_date">{{$parent.$parent.formatDate('d/m/Y', noticia.actived_at)}}</h5>
                            <h4 class="news_title">
                                <router-link :to="`/noticias/${noticia.url}`" class="">{{noticia.title}}</router-link>
                            </h4>
                            <p>{{noticia.shortdesc}}</p>
                            <div class="news_categories"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn med center white wide" @click="$parent.getNoticias">Cargar más</div>
        </article>
    </div>
</template>

<script>
export default {
    name: 'Noticias',
    props: {
        noticias: Array
    }
}
</script>