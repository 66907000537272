<template>
    <div class="map__container">
        <iframe v-if="iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23569665.12456294!2d-12.8611109417314!3d43.85258716626324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ed8886cfadda85%3A0x72ef99e6b3fcf079!2sEuropa!5e0!3m2!1ses!2ses!4v1616424504210!5m2!1ses!2ses" width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        <GmapMap
            v-else
            ref="mapRef"
            :center="{lat:18.579943, lng:-39.574499}"
            :options="options"
            style="width: 100%; height: 100%"
        >
            <GmapMarker
                v-for="(doctor, index) in doctores"
                :key="index"
                :position="google && new google.maps.LatLng(doctor.lat, doctor.lng)"
                :clickable="true"
                :draggable="false"
                :icon="{
                    url: require('@/assets/images/map_icon.png'),
                    scaledSize: new google.maps.Size(50, 50)
                }"
                :animation="google && google.maps.Animation.DROP"
                @click="$router.push(`/doctores/${doctor.url}`)"
            />
            </GmapMap>
    </div>
</template>

<script>
import axios from 'axios'
import {gmapApi} from 'vue2-google-maps'

export default {
    name: 'Mapa',
    props: {
        iframe: {
            type: Boolean,
            default: false
        },
        doctors: Array
    },
    data: function(){
        return{
            doctores: this.doctors,
            options: {
                zoom: 2.5,
                mapTypeId: "roadmap",
                mapTypeControl: false,
                scaleControl: false,
                scrollwheel: true,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: true,
                zoomControlOptions: {
                    position: 11
                },
                styles: [
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#6195a0"
            }
        ]
    },
    {
        "featureType": "administrative.province",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "lightness": "0"
            },
            {
                "saturation": "0"
            },
            {
                "color": "#f5f5f2"
            },
            {
                "gamma": "1"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "all",
        "stylers": [
            {
                "lightness": "-3"
            },
            {
                "gamma": "1.00"
            }
        ]
    },
    {
        "featureType": "landscape.natural.terrain",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#bae5ce"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fac9a9"
            },
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.text",
        "stylers": [
            {
                "color": "#4e4e4e"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#787878"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "transit.station.airport",
        "elementType": "labels.icon",
        "stylers": [
            {
                "hue": "#0a00ff"
            },
            {
                "saturation": "-77"
            },
            {
                "gamma": "0.57"
            },
            {
                "lightness": "0"
            }
        ]
    },
    {
        "featureType": "transit.station.rail",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#43321e"
            }
        ]
    },
    {
        "featureType": "transit.station.rail",
        "elementType": "labels.icon",
        "stylers": [
            {
                "hue": "#ff6c00"
            },
            {
                "lightness": "4"
            },
            {
                "gamma": "0.75"
            },
            {
                "saturation": "-68"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#eaf6f8"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c7eced"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "lightness": "-49"
            },
            {
                "saturation": "-53"
            },
            {
                "gamma": "0.79"
            }
        ]
    }
]
            }
        }
    },
    methods: {
        getAllDoctores: function(){
            axios
                .get(window.URL+'specialists', {
                    params: {
                        'orderBy[column]': 'name',
                        'orderBy[order]': 'asc',
                        active: 1,
                        trusted: 1,
                        specialty: 1
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.doctores = rs.data;
                        this.refresh();
                    }
                })
        },
        refresh: function(){
            if(this.doctores.length <= 0 ){
                this.getAllDoctores();
                return;
            }
            
            this.$refs.mapRef.$mapPromise.then((map) => {
                var latlngbounds = new google.maps.LatLngBounds();
                this.doctores.forEach(element => {
                    latlngbounds.extend(new this.google.maps.LatLng(element.lat, element.lng));
                });

                if (latlngbounds.getNorthEast().equals(latlngbounds.getSouthWest())){
                    var extendPoint1 = new google.maps.LatLng(latlngbounds.getNorthEast().lat() + 0.01, latlngbounds.getNorthEast().lng() + 0.01);
                    var extendPoint2 = new google.maps.LatLng(latlngbounds.getNorthEast().lat() - 0.01, latlngbounds.getNorthEast().lng() - 0.01);
                    latlngbounds.extend(extendPoint1);
                    latlngbounds.extend(extendPoint2);
                }

                map.fitBounds(latlngbounds);
            });
        }
    },
    mounted: function(){
        this.refresh();
    },
    watch:{
        doctors: function(){
            this.doctores = this.doctors;
            this.refresh();
        },
    },
    computed: {
        google: gmapApi
    }
}
</script>

<style>
    .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint{transform: rotateZ(-90deg)translateX(-20px);}
    .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint > div {border-radius: 25px!important;}
    .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom > .gmnoprint button {transform: rotateZ(90deg);border-radius: 25px!important;}
</style>