<template>
    <div class="intro">
        <div class="intro_text">
            <h2>¡Hola! Bienvenido a Mi Urólogo de Confianza</h2>
            <p>Mi urólogo y andrólogo de confianza es un proyecto sin ánimo de lucro en el que queremos poner al servicio del consumidor una información gratuita basada en parámetros médicos, opiniones de pacientes y datos recogidos en distintos medios de comunicación que hacen referencia a los mejores especialistas en urología, andrología y salud sexual.</p>
        </div>
    </div>
</template>

<script>
export default {
    name:'Intro'
}
</script>