<template>
    <footer class="artifact__footer">
        <div class="footer__container">
            <div class="footer__logo">
                <svg version="1.0" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 402.52 99.21" style="enable-background:new 0 0 402.52 99.21;height: 40px;" xml:space="preserve">
                <path class="st0" d="M33.24,53.29V4.23c0-2.16,1.6-4.02,3.64-4.21C37.01,0.01,37.15,0,37.28,0c2.23,0,4.04,1.81,4.04,4.04v49.25
                    H33.24z"/>
                <path class="st0" d="M49.53,99.21c-10.2,0-16.29-6.47-16.29-17.31V70.57h8.08v10.68c0,7.38,2.38,10.38,8.21,10.38
                    c3.59,0,8.36-1.08,8.36-10.38V45.92h8.08V81.9C65.97,92.74,59.83,99.21,49.53,99.21z"/>
                <path class="st0" d="M57.89,28.64V4.04C57.89,1.81,59.7,0,61.93,0c2.23,0,4.04,1.81,4.04,4.04v24.6H57.89z"/>
                <path class="st0" d="M45.92,41.32v-8.08h49.25c2.23,0,4.04,1.81,4.04,4.04c0,2.23-1.81,4.04-4.04,4.04H45.92z"/>
                <path class="st0" d="M17.31,65.97C6.47,65.97,0,59.83,0,49.53c0-10.2,6.47-16.29,17.31-16.29h11.33v8.08H17.96
                    c-7.38,0-10.38,2.38-10.38,8.21c0,3.59,1.08,8.36,10.38,8.36h35.33v8.08H17.31z"/>
                <path class="st0" d="M70.57,65.97v-8.08h24.6c2.23,0,4.04,1.81,4.04,4.04c0,2.23-1.81,4.04-4.04,4.04H70.57z"/>
                <path class="st1" d="M125.71,13.48c0.43,0,0.89,0.12,1.36,0.35c0.48,0.23,0.84,0.53,1.09,0.9l10.07,15.5l10.38-15.5
                    c0.59-0.83,1.34-1.25,2.27-1.25c0.74,0,1.4,0.26,1.99,0.79c0.59,0.52,0.88,1.2,0.88,2.04v26.78c0,0.8-0.26,1.47-0.79,2.01
                    c-0.53,0.54-1.22,0.81-2.08,0.81c-0.86,0-1.57-0.27-2.1-0.81c-0.54-0.54-0.81-1.21-0.81-2.01V24.46l-7.63,11.64
                    c-0.28,0.34-0.62,0.63-1.04,0.88c-0.42,0.25-0.84,0.36-1.27,0.32c-0.4,0.03-0.81-0.08-1.23-0.32c-0.42-0.25-0.76-0.54-1.04-0.88
                    l-7.63-12.07l0,19.05c0,0.8-0.25,1.47-0.74,2.01c-0.49,0.54-1.14,0.81-1.94,0.81c-0.77,0-1.4-0.27-1.9-0.81
                    c-0.49-0.54-0.74-1.21-0.74-2.01V16.3c0-0.77,0.28-1.43,0.86-1.99C124.22,13.76,124.91,13.48,125.71,13.48z"/>
                <path class="st1" d="M162.62,18.15c-1.05,0-1.79-0.17-2.22-0.51c-0.43-0.34-0.65-0.94-0.65-1.8v-0.88c0-0.89,0.24-1.5,0.72-1.83
                    c0.48-0.32,1.21-0.49,2.2-0.49c1.08,0,1.83,0.17,2.27,0.51c0.43,0.34,0.65,0.94,0.65,1.8v0.88c0,0.89-0.23,1.5-0.69,1.83
                    C164.43,17.99,163.67,18.15,162.62,18.15z"/>
                <path class="st1" d="M165.45,43.08c0,0.8-0.26,1.47-0.79,2.01c-0.53,0.54-1.19,0.81-1.99,0.81c-0.8,0-1.47-0.27-1.99-0.81
                    c-0.53-0.54-0.79-1.21-0.79-2.01v-19.1c0-0.8,0.26-1.47,0.79-2.01c0.52-0.54,1.19-0.81,1.99-0.81c0.8,0,1.46,0.27,1.99,0.81
                    c0.52,0.54,0.79,1.21,0.79,2.01V43.08z"/>
                <path class="st1" d="M202.45,21.16c0.8,0,1.46,0.27,1.99,0.81c0.52,0.54,0.79,1.21,0.79,2.01v11.75c0,3.27-0.91,5.86-2.73,7.77
                    c-1.82,1.91-4.44,2.87-7.86,2.87c-3.42,0-6.04-0.96-7.84-2.87c-1.8-1.91-2.71-4.5-2.71-7.77V23.98c0-0.8,0.26-1.47,0.79-2.01
                    c0.52-0.54,1.19-0.81,1.99-0.81c0.8,0,1.46,0.27,1.99,0.81c0.52,0.54,0.79,1.21,0.79,2.01v11.75c0,1.88,0.42,3.28,1.25,4.19
                    c0.83,0.91,2.08,1.36,3.75,1.36c1.7,0,2.96-0.45,3.79-1.36c0.83-0.91,1.25-2.3,1.25-4.19V23.98c0-0.8,0.26-1.47,0.79-2.01
                    C200.99,21.43,201.65,21.16,202.45,21.16z"/>
                <path class="st1" d="M214.02,45.91c-0.8,0-1.47-0.27-1.99-0.81c-0.53-0.54-0.79-1.21-0.79-2.01v-19.1c0-0.8,0.26-1.47,0.79-2.01
                    c0.52-0.54,1.19-0.81,1.99-0.81c0.8,0,1.46,0.27,1.99,0.81c0.52,0.54,0.79,1.21,0.79,2.01v0.62c0,0,0.45-0.77,0.99-1.34
                    c0.54-0.57,1.15-1.05,1.83-1.43c0.68-0.39,1.4-0.67,2.17-0.86s1.54-0.28,2.31-0.28c0.93,0,1.7,0.26,2.34,0.79
                    c0.63,0.52,0.95,1.14,0.95,1.85c0,1.02-0.26,1.75-0.79,2.2c-0.52,0.45-1.09,0.67-1.71,0.67c-0.59,0-1.12-0.11-1.6-0.32
                    c-0.48-0.22-1.03-0.32-1.64-0.32c-0.55,0-1.12,0.13-1.69,0.39c-0.57,0.26-1.09,0.67-1.57,1.23c-0.48,0.55-0.86,1.24-1.16,2.06
                    c-0.29,0.82-0.44,1.78-0.44,2.89v10.96c0,0.8-0.26,1.47-0.79,2.01C215.48,45.64,214.82,45.91,214.02,45.91z"/>
                <path class="st1" d="M253.41,26.87c-1.13-1.93-2.64-3.44-4.53-4.53c-1.9-1.09-4.02-1.64-6.36-1.64c-2.34,0-4.46,0.55-6.36,1.64
                    c-1.9,1.1-3.41,2.61-4.53,4.53c-1.13,1.93-1.69,4.16-1.69,6.68c0,2.53,0.56,4.76,1.69,6.68c1.13,1.93,2.64,3.43,4.53,4.51
                    c1.9,1.08,4.02,1.62,6.36,1.62c2.34,0,4.46-0.54,6.36-1.62c1.9-1.08,3.41-2.58,4.53-4.51c1.13-1.93,1.69-4.16,1.69-6.68
                    C255.09,31.03,254.53,28.8,253.41,26.87z M248.59,37.6c-0.63,1.16-1.48,2.06-2.54,2.71c-1.06,0.65-2.24,0.97-3.54,0.97
                    c-1.3,0-2.47-0.32-3.54-0.97c-1.06-0.65-1.91-1.55-2.54-2.71c-0.63-1.16-0.95-2.51-0.95-4.05c0-1.57,0.32-2.94,0.95-4.09
                    c0.63-1.16,1.48-2.06,2.54-2.71c1.06-0.65,2.24-0.97,3.54-0.97c1.3,0,2.47,0.32,3.54,0.97c1.06,0.65,1.91,1.55,2.54,2.71
                    c0.63,1.16,0.95,2.52,0.95,4.09C249.54,35.1,249.23,36.45,248.59,37.6z"/>
                <path class="st1" d="M242.74,19.58c-0.25,0-0.56-0.13-0.95-0.39c-0.39-0.26-0.56-0.64-0.53-1.13c0-0.28,0.09-0.57,0.28-0.88
                    l2.68-4.72c0.28-0.49,0.63-0.93,1.06-1.3c0.43-0.37,0.93-0.56,1.48-0.56c0.86,0,1.5,0.22,1.9,0.67c0.4,0.45,0.59,0.99,0.55,1.64
                    c-0.03,0.31-0.12,0.63-0.25,0.97c-0.14,0.34-0.35,0.68-0.62,1.02l-3.52,3.89c-0.31,0.34-0.65,0.56-1.02,0.65
                    C243.44,19.54,243.08,19.58,242.74,19.58z"/>
                <path class="st1" d="M265.73,43.08c0,0.8-0.27,1.47-0.81,2.01c-0.54,0.54-1.21,0.81-2.01,0.81c-0.77,0-1.42-0.27-1.94-0.81
                    c-0.53-0.54-0.79-1.21-0.79-2.01V14.5c0-0.8,0.27-1.47,0.81-2.01c0.54-0.54,1.21-0.81,2.01-0.81c0.8,0,1.46,0.27,1.97,0.81
                    c0.51,0.54,0.76,1.21,0.76,2.01V43.08z"/>
                <path class="st1" d="M295.52,33.55c0,2.53-0.56,4.76-1.69,6.68c-1.13,1.93-2.64,3.43-4.53,4.51c-1.9,1.08-4.02,1.62-6.36,1.62
                    c-2.34,0-4.46-0.54-6.36-1.62c-1.9-1.08-3.41-2.58-4.53-4.51c-1.13-1.93-1.69-4.16-1.69-6.68c0-2.53,0.56-4.76,1.69-6.68
                    c1.13-1.93,2.64-3.44,4.53-4.53c1.9-1.09,4.02-1.64,6.36-1.64c2.34,0,4.46,0.55,6.36,1.64c1.9,1.09,3.41,2.61,4.53,4.53
                    C294.96,28.8,295.52,31.03,295.52,33.55z M289.97,33.55c0-1.57-0.32-2.94-0.95-4.09c-0.63-1.16-1.48-2.06-2.54-2.71
                    c-1.06-0.65-2.24-0.97-3.54-0.97s-2.47,0.32-3.54,0.97c-1.06,0.65-1.91,1.55-2.54,2.71c-0.63,1.16-0.95,2.52-0.95,4.09
                    c0,1.54,0.32,2.89,0.95,4.05c0.63,1.16,1.48,2.06,2.54,2.71c1.06,0.65,2.24,0.97,3.54,0.97s2.47-0.32,3.54-0.97
                    c1.06-0.65,1.91-1.55,2.54-2.71C289.66,36.45,289.97,35.1,289.97,33.55z"/>
                <path class="st1" d="M322.63,21.48c-0.52-0.52-1.19-0.79-1.99-0.79c-0.8,0-1.47,0.27-1.99,0.81c-0.52,0.54-0.79,1.21-0.79,2.01
                    l0,0.52c-0.37-0.41-0.81-0.81-1.32-1.21c-0.79-0.62-1.72-1.13-2.8-1.53c-1.08-0.4-2.27-0.6-3.56-0.6c-2.13,0-4.05,0.55-5.78,1.64
                    c-1.73,1.1-3.1,2.61-4.12,4.53c-1.02,1.93-1.53,4.16-1.53,6.68c0,2.5,0.51,4.71,1.53,6.64c1.02,1.93,2.41,3.44,4.16,4.53
                    c1.76,1.09,3.73,1.64,5.92,1.64c1.11,0,2.16-0.19,3.15-0.58c0.99-0.38,1.84-0.86,2.57-1.41c0.72-0.55,1.3-1.12,1.71-1.69
                    c0.03-0.04,0.05-0.07,0.07-0.1v2.25c0,1.63-0.56,2.91-1.67,3.84c-1.11,0.93-2.82,1.39-5.14,1.39c-0.65,0-1.4-0.12-2.24-0.35
                    c-0.85-0.23-1.63-0.49-2.34-0.76c-0.71-0.28-1.22-0.46-1.53-0.56c-0.68-0.25-1.33-0.21-1.94,0.12c-0.62,0.32-1.06,0.93-1.34,1.83
                    c-0.22,0.68-0.15,1.32,0.21,1.92c0.35,0.6,0.99,1.1,1.92,1.5c0.46,0.19,1.14,0.39,2.04,0.6c0.89,0.22,1.83,0.4,2.82,0.56
                    c0.99,0.15,1.79,0.23,2.41,0.23c2.16,0,4.18-0.36,6.06-1.09c1.88-0.73,3.4-1.9,4.56-3.52c1.16-1.62,1.73-3.77,1.73-6.45V23.52
                    C323.42,22.68,323.16,22.01,322.63,21.48z M317.22,37.49c-0.59,1.17-1.4,2.1-2.45,2.78c-1.05,0.68-2.28,1.02-3.7,1.02
                    c-1.39,0-2.61-0.34-3.65-1.02c-1.05-0.68-1.87-1.6-2.45-2.78c-0.59-1.17-0.88-2.48-0.88-3.93c0-1.48,0.29-2.81,0.88-3.98
                    c0.59-1.17,1.4-2.1,2.45-2.78c1.05-0.68,2.27-1.02,3.65-1.02c1.42,0,2.65,0.34,3.7,1.02c1.05,0.68,1.86,1.6,2.45,2.78
                    c0.59,1.17,0.88,2.5,0.88,3.98C318.1,35,317.8,36.31,317.22,37.49z"/>
                <path class="st1" d="M352.28,33.55c0,2.53-0.56,4.76-1.69,6.68c-1.13,1.93-2.64,3.43-4.53,4.51c-1.9,1.08-4.02,1.62-6.36,1.62
                    c-2.34,0-4.46-0.54-6.36-1.62c-1.9-1.08-3.41-2.58-4.53-4.51c-1.13-1.93-1.69-4.16-1.69-6.68c0-2.53,0.56-4.76,1.69-6.68
                    c1.13-1.93,2.64-3.44,4.53-4.53c1.9-1.09,4.02-1.64,6.36-1.64c2.34,0,4.46,0.55,6.36,1.64c1.9,1.09,3.41,2.61,4.53,4.53
                    C351.72,28.8,352.28,31.03,352.28,33.55z M346.73,33.55c0-1.57-0.32-2.94-0.95-4.09c-0.63-1.16-1.48-2.06-2.54-2.71
                    c-1.06-0.65-2.24-0.97-3.54-0.97c-1.3,0-2.47,0.32-3.54,0.97c-1.06,0.65-1.91,1.55-2.54,2.71c-0.63,1.16-0.95,2.52-0.95,4.09
                    c0,1.54,0.32,2.89,0.95,4.05c0.63,1.16,1.48,2.06,2.54,2.71c1.06,0.65,2.24,0.97,3.54,0.97c1.3,0,2.47-0.32,3.54-0.97
                    c1.06-0.65,1.91-1.55,2.54-2.71C346.42,36.45,346.73,35.1,346.73,33.55z"/>
                <path class="st1" d="M144.35,54.7c-0.52-0.52-1.19-0.79-1.99-0.79c-0.8,0-1.47,0.27-1.99,0.81c-0.52,0.54-0.79,1.21-0.79,2.01v9.08
                    c-0.27-0.25-0.57-0.5-0.9-0.75c-0.82-0.62-1.78-1.13-2.89-1.53c-1.11-0.4-2.33-0.6-3.65-0.6c-2.07,0-3.95,0.55-5.64,1.64
                    c-1.7,1.09-3.05,2.61-4.05,4.53c-1,1.93-1.5,4.16-1.5,6.68c0,2.5,0.5,4.71,1.5,6.64c1,1.93,2.37,3.44,4.09,4.53
                    c1.73,1.09,3.65,1.64,5.78,1.64c1.17,0,2.26-0.19,3.26-0.58c1-0.38,1.87-0.86,2.61-1.41c0.55-0.41,1.01-0.83,1.39-1.25
                    c0.01,0.78,0.27,1.44,0.78,1.97c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V56.73
                    C145.14,55.9,144.88,55.22,144.35,54.7z M138.96,79.72c-0.57,1.17-1.37,2.1-2.38,2.78s-2.21,1.02-3.56,1.02
                    c-1.33,0-2.5-0.34-3.52-1.02c-1.02-0.68-1.81-1.6-2.38-2.78c-0.57-1.17-0.86-2.48-0.86-3.93c0-1.48,0.28-2.81,0.86-3.98
                    c0.57-1.17,1.36-2.1,2.38-2.78c1.02-0.68,2.19-1.02,3.52-1.02c1.36,0,2.54,0.34,3.56,1.02c1.02,0.68,1.81,1.6,2.38,2.78
                    c0.57,1.17,0.86,2.5,0.86,3.98C139.82,77.24,139.53,78.55,138.96,79.72z"/>
                <path class="st1" d="M172.48,76.99c0.55-0.46,0.85-1.06,0.88-1.8c0-1.67-0.32-3.24-0.95-4.72c-0.63-1.48-1.49-2.78-2.57-3.91
                    c-1.08-1.13-2.32-2.01-3.72-2.66c-1.4-0.65-2.89-0.97-4.46-0.97c-2.03,0-3.96,0.52-5.78,1.57c-1.82,1.05-3.29,2.55-4.42,4.51
                    c-1.13,1.96-1.69,4.32-1.69,7.1c0,2.38,0.52,4.5,1.55,6.38c1.03,1.88,2.51,3.37,4.44,4.46c1.93,1.09,4.2,1.64,6.82,1.64
                    c1.54,0,3.04-0.25,4.49-0.76c1.45-0.51,2.57-1.1,3.38-1.78c0.86-0.68,1.3-1.43,1.3-2.27c0-0.62-0.22-1.16-0.67-1.62
                    c-0.45-0.46-1.01-0.69-1.69-0.69c-0.49,0-1.02,0.14-1.57,0.42c-0.34,0.19-0.72,0.41-1.13,0.67c-0.42,0.26-0.96,0.49-1.64,0.67
                    c-0.68,0.19-1.59,0.28-2.73,0.28c-1.23,0-2.4-0.3-3.49-0.9c-1.1-0.6-1.99-1.48-2.68-2.64c-0.4-0.67-0.68-1.44-0.84-2.29h15.25
                    C171.27,77.69,171.92,77.45,172.48,76.99z M167.44,73.06h-12.11c0.07-0.38,0.16-0.74,0.27-1.06c0.34-0.99,0.8-1.77,1.39-2.36
                    c0.59-0.59,1.28-1,2.08-1.25c0.8-0.25,1.66-0.37,2.59-0.37c0.96,0,1.86,0.21,2.71,0.62c0.85,0.42,1.56,0.98,2.13,1.69
                    c0.57,0.71,0.89,1.51,0.95,2.41V73.06z"/>
                <path class="st1" d="M201.71,62.93c1.76,0,3.29,0.18,4.6,0.55c1.31,0.37,2.33,0.89,3.05,1.55c0.72,0.66,1.09,1.46,1.09,2.38
                    c0,0.62-0.19,1.2-0.56,1.73c-0.37,0.54-0.91,0.81-1.62,0.81c-0.49,0-0.9-0.07-1.23-0.21c-0.32-0.14-0.61-0.32-0.86-0.53
                    c-0.25-0.22-0.54-0.42-0.88-0.6c-0.31-0.18-0.78-0.33-1.41-0.44c-0.63-0.11-1.1-0.16-1.41-0.16c-1.57,0-2.91,0.34-4,1.02
                    c-1.09,0.68-1.94,1.6-2.52,2.75c-0.59,1.16-0.88,2.49-0.88,4c0,1.48,0.3,2.8,0.9,3.96c0.6,1.16,1.43,2.07,2.5,2.75
                    c1.06,0.68,2.29,1.02,3.68,1.02c0.77,0,1.43-0.05,1.99-0.14c0.55-0.09,1.02-0.23,1.39-0.42c0.43-0.25,0.82-0.51,1.16-0.79
                    c0.34-0.28,0.85-0.42,1.53-0.42c0.8,0,1.42,0.25,1.85,0.76c0.43,0.51,0.65,1.13,0.65,1.87c0,0.77-0.43,1.47-1.3,2.1
                    c-0.86,0.63-2,1.14-3.4,1.53c-1.4,0.39-2.92,0.58-4.56,0.58c-2.44,0-4.55-0.56-6.34-1.69c-1.79-1.13-3.17-2.66-4.14-4.6
                    c-0.97-1.94-1.46-4.12-1.46-6.52c0-2.53,0.52-4.76,1.55-6.68c1.03-1.93,2.47-3.44,4.3-4.53C197.23,63.48,199.34,62.93,201.71,62.93z
                    "/>
                <path class="st1" d="M236.85,69.11c-1.13-1.93-2.64-3.44-4.53-4.53c-1.9-1.09-4.02-1.64-6.36-1.64c-2.34,0-4.46,0.55-6.36,1.64
                    c-1.9,1.09-3.41,2.61-4.53,4.53c-1.13,1.93-1.69,4.16-1.69,6.68c0,2.53,0.56,4.76,1.69,6.68c1.13,1.93,2.64,3.43,4.53,4.51
                    c1.9,1.08,4.02,1.62,6.36,1.62c2.34,0,4.46-0.54,6.36-1.62c1.9-1.08,3.41-2.58,4.53-4.51c1.13-1.93,1.69-4.16,1.69-6.68
                    C238.53,73.26,237.97,71.03,236.85,69.11z M232.03,79.84c-0.63,1.16-1.48,2.06-2.54,2.71c-1.06,0.65-2.24,0.97-3.54,0.97
                    c-1.3,0-2.47-0.32-3.54-0.97c-1.06-0.65-1.91-1.55-2.54-2.71c-0.63-1.16-0.95-2.51-0.95-4.05c0-1.57,0.32-2.94,0.95-4.09
                    c0.63-1.16,1.48-2.06,2.54-2.71c1.06-0.65,2.24-0.97,3.54-0.97c1.3,0,2.47,0.32,3.54,0.97c1.06,0.65,1.91,1.55,2.54,2.71
                    c0.63,1.16,0.95,2.52,0.95,4.09C232.98,77.33,232.67,78.68,232.03,79.84z"/>
                <path class="st1" d="M264.05,68.18c-0.45-1.59-1.26-2.86-2.45-3.82c-1.19-0.96-2.92-1.43-5.2-1.43c-1.05,0-2.02,0.16-2.91,0.49
                    c-0.89,0.32-1.7,0.75-2.41,1.27c-0.71,0.52-1.33,1.09-1.85,1.69c-0.19,0.22-0.35,0.43-0.51,0.64v-0.8c0-0.8-0.26-1.47-0.79-2.01
                    c-0.52-0.54-1.19-0.81-1.99-0.81c-0.8,0-1.47,0.27-1.99,0.81c-0.53,0.54-0.79,1.21-0.79,2.01v19.1c0,0.8,0.26,1.47,0.79,2.01
                    c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V73.48c0-1.02,0.22-1.93,0.67-2.75
                    s1.1-1.47,1.94-1.97c0.85-0.49,1.89-0.74,3.12-0.74c1.26,0,2.24,0.25,2.91,0.74c0.68,0.49,1.15,1.15,1.41,1.97s0.39,1.73,0.39,2.75
                    v11.84c0,0.8,0.26,1.47,0.79,2.01c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V73.48
                    C264.72,71.53,264.49,69.77,264.05,68.18z"/>
                <path class="st1" d="M279.98,60.5c0.15-0.45,0.44-0.84,0.86-1.18c0.42-0.34,1.01-0.51,1.78-0.51c0.52,0,1.06,0.07,1.62,0.21
                    s0.97,0.21,1.25,0.21c0.59,0,1.1-0.22,1.55-0.67c0.45-0.45,0.67-1.06,0.67-1.83c0-0.68-0.22-1.23-0.65-1.64
                    c-0.43-0.42-0.97-0.72-1.62-0.9c-0.65-0.19-1.31-0.28-1.99-0.28c-3.08,0-5.4,0.76-6.94,2.27c-1.54,1.51-2.31,3.36-2.31,5.55v2.13
                    h-2.5c-0.74,0-1.36,0.24-1.85,0.72c-0.49,0.48-0.74,1.09-0.74,1.83c0,0.74,0.25,1.35,0.74,1.83c0.49,0.48,1.11,0.72,1.85,0.72h2.5
                    v16.38c0,0.8,0.26,1.47,0.79,2.01c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V68.94
                    h4.21c0.74,0,1.36-0.24,1.85-0.72c0.49-0.48,0.74-1.09,0.74-1.83c0-0.74-0.25-1.35-0.74-1.83c-0.49-0.48-1.11-0.72-1.85-0.72h-4.21
                    v-2.17C279.75,61.34,279.83,60.95,279.98,60.5z"/>
                <path class="st1" d="M292.43,60.39c-1.05,0-1.79-0.17-2.22-0.51c-0.43-0.34-0.65-0.94-0.65-1.8v-0.88c0-0.89,0.24-1.5,0.72-1.83
                    c0.48-0.32,1.21-0.49,2.2-0.49c1.08,0,1.83,0.17,2.27,0.51c0.43,0.34,0.65,0.94,0.65,1.8v0.88c0,0.89-0.23,1.5-0.69,1.83
                    C294.23,60.22,293.47,60.39,292.43,60.39z"/>
                <path class="st1" d="M295.25,85.32c0,0.8-0.26,1.47-0.79,2.01c-0.52,0.54-1.19,0.81-1.99,0.81c-0.8,0-1.47-0.27-1.99-0.81
                    c-0.52-0.54-0.79-1.21-0.79-2.01v-19.1c0-0.8,0.26-1.47,0.79-2.01c0.52-0.54,1.19-0.81,1.99-0.81c0.8,0,1.46,0.27,1.99,0.81
                    c0.52,0.54,0.79,1.21,0.79,2.01V85.32z"/>
                <path class="st1" d="M322.96,63.72c-0.52-0.52-1.19-0.79-1.99-0.79c-0.8,0-1.47,0.27-1.99,0.81c-0.52,0.54-0.79,1.21-0.79,2.01v0.07
                    c-0.27-0.26-0.57-0.51-0.9-0.76c-0.82-0.62-1.78-1.13-2.89-1.53c-1.11-0.4-2.33-0.6-3.65-0.6c-2.07,0-3.95,0.55-5.64,1.64
                    c-1.7,1.09-3.05,2.61-4.05,4.53c-1,1.93-1.5,4.16-1.5,6.68c0,2.5,0.5,4.71,1.5,6.64c1,1.93,2.37,3.44,4.09,4.53
                    c1.73,1.09,3.65,1.64,5.78,1.64c1.17,0,2.26-0.19,3.26-0.58c1-0.38,1.87-0.86,2.61-1.41c0.55-0.41,1.01-0.83,1.39-1.25
                    c0.01,0.78,0.27,1.44,0.78,1.97c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V65.75
                    C323.74,64.92,323.48,64.24,322.96,63.72z M317.57,79.72c-0.57,1.17-1.37,2.1-2.38,2.78c-1.02,0.68-2.21,1.02-3.56,1.02
                    c-1.33,0-2.5-0.34-3.52-1.02c-1.02-0.68-1.81-1.6-2.38-2.78c-0.57-1.17-0.86-2.48-0.86-3.93c0-1.48,0.28-2.81,0.86-3.98
                    c0.57-1.17,1.37-2.1,2.38-2.78c1.02-0.68,2.19-1.02,3.52-1.02c1.36,0,2.54,0.34,3.56,1.02c1.02,0.68,1.81,1.6,2.38,2.78
                    c0.57,1.17,0.86,2.5,0.86,3.98C318.42,77.24,318.14,78.55,317.57,79.72z"/>
                <path class="st1" d="M350.64,68.18c-0.45-1.59-1.26-2.86-2.45-3.82c-1.19-0.96-2.92-1.43-5.2-1.43c-1.05,0-2.02,0.16-2.91,0.49
                    c-0.89,0.32-1.7,0.75-2.41,1.27c-0.71,0.52-1.33,1.09-1.85,1.69c-0.19,0.22-0.35,0.43-0.51,0.64v-0.8c0-0.8-0.26-1.47-0.79-2.01
                    c-0.52-0.54-1.19-0.81-1.99-0.81c-0.8,0-1.47,0.27-1.99,0.81c-0.53,0.54-0.79,1.21-0.79,2.01v19.1c0,0.8,0.26,1.47,0.79,2.01
                    c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V73.48c0-1.02,0.22-1.93,0.67-2.75
                    s1.09-1.47,1.94-1.97c0.85-0.49,1.89-0.74,3.12-0.74c1.26,0,2.24,0.25,2.91,0.74c0.68,0.49,1.15,1.15,1.41,1.97s0.39,1.73,0.39,2.75
                    v11.84c0,0.8,0.26,1.47,0.79,2.01c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V73.48
                    C351.31,71.53,351.09,69.77,350.64,68.18z"/>
                <path class="st1" d="M374.95,83.77c-0.49-0.48-1.11-0.72-1.85-0.72h-9.69l11.12-14.39c0.34-0.43,0.55-0.8,0.62-1.11
                    s0.13-0.62,0.16-0.93c0.06-0.8-0.15-1.46-0.65-1.99c-0.49-0.52-1.09-0.79-1.8-0.79h-14.25c-0.74,0-1.36,0.24-1.85,0.72
                    c-0.49,0.48-0.74,1.07-0.74,1.78c0,0.74,0.25,1.36,0.74,1.85c0.49,0.49,1.11,0.74,1.85,0.74h8.98l-11.02,14.25
                    c-0.37,0.46-0.6,0.87-0.69,1.23c-0.09,0.36-0.16,0.76-0.19,1.23c-0.03,0.56,0.19,1.11,0.65,1.67c0.46,0.55,1.08,0.83,1.85,0.83
                    h14.89c0.74,0,1.36-0.25,1.85-0.74c0.49-0.49,0.74-1.09,0.74-1.8C375.69,84.86,375.44,84.25,374.95,83.77z"/>
                <path class="st1" d="M401.73,63.72c-0.52-0.52-1.19-0.79-1.99-0.79c-0.8,0-1.47,0.27-1.99,0.81c-0.52,0.54-0.79,1.21-0.79,2.01v0.07
                    c-0.27-0.26-0.57-0.51-0.9-0.76c-0.82-0.62-1.78-1.13-2.89-1.53c-1.11-0.4-2.33-0.6-3.65-0.6c-2.07,0-3.95,0.55-5.64,1.64
                    c-1.7,1.09-3.05,2.61-4.05,4.53c-1,1.93-1.5,4.16-1.5,6.68c0,2.5,0.5,4.71,1.5,6.64c1,1.93,2.37,3.44,4.09,4.53
                    c1.73,1.09,3.65,1.64,5.78,1.64c1.17,0,2.26-0.19,3.26-0.58c1-0.38,1.87-0.86,2.61-1.41c0.55-0.41,1.01-0.83,1.39-1.25
                    c0.01,0.78,0.27,1.44,0.78,1.97c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V65.75
                    C402.52,64.92,402.26,64.24,401.73,63.72z M396.34,79.72c-0.57,1.17-1.37,2.1-2.38,2.78s-2.21,1.02-3.56,1.02
                    c-1.33,0-2.5-0.34-3.52-1.02s-1.81-1.6-2.38-2.78c-0.57-1.17-0.86-2.48-0.86-3.93c0-1.48,0.28-2.81,0.86-3.98
                    c0.57-1.17,1.37-2.1,2.38-2.78c1.02-0.68,2.19-1.02,3.52-1.02c1.36,0,2.54,0.34,3.56,1.02c1.02,0.68,1.81,1.6,2.38,2.78
                    c0.57,1.17,0.86,2.5,0.86,3.98C397.2,77.24,396.91,78.55,396.34,79.72z"/>
                </svg>
            </div>
            <div class="legal__text">
                <p>©2021 Factoría audiovisual S.R.L.</p>
                <p>Todos los derechos reservados. Cualquier copia o reproducción, total o parcial de los contenidos de esta web, está totalmente prohibido sin consentimiento expreso.</p>
                <p>Factoría audiovisual S.R.L. CIF: B82690744, 28240 Hoyo de Manzanares, Registro Mercantil de Madrid, Tomo 15744, libro 0, Folio 19, Sección 8, Hoja M-265464, Inscripción 1ª, fecha 05 de octubre de 2000.</p>
            </div>
            <div class="footer_credits">
                <span>Powered by:</span>
                <a href="https://www.faudiovisual.com/" target="_blank" class="author_credits" title="Factoría Audiovisual">
                    <svg version="1.0" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 100 26.54" style="enable-background:new 0 0 100 26.54;" xml:space="preserve">
                    <path class="st0" d="M30.46,21.67C30.46,21.66,30.46,21.66,30.46,21.67L18.08,0.19C18.01,0.08,17.88,0,17.74,0l-4.96,0h0
                        c-0.14,0-0.27,0.07-0.33,0.19L0.05,21.65c-0.07,0.12-0.07,0.27,0,0.39l2.48,4.29c0.07,0.12,0.2,0.19,0.33,0.19l4.96,0h0
                        c0.14,0,0.27-0.07,0.33-0.19l2.15-3.71l2.14,3.72c0.07,0.12,0.2,0.19,0.33,0.19l4.96,0h0c0.14,0,0.27-0.07,0.33-0.19l2.15-3.72
                        l2.14,3.71c0.07,0.12,0.2,0.19,0.33,0.19l4.96,0c0,0,0,0,0,0c0.14,0,0.27-0.07,0.33-0.19l2.48-4.29
                        C30.53,21.93,30.53,21.78,30.46,21.67z M17.52,0.77l11.94,20.7l-4.07,0L17.55,7.88l-4.1-7.11L17.52,0.77z M7.6,25.76l-4.07,0
                        l4.29-7.43l2.03,3.52L7.6,25.76z M17.51,25.76l-4.07,0l0.26-0.45l1.78-3.07l4.07,0L17.51,25.76z M15.04,13.65l0.38,0.66l4.13,7.16
                        l-4.04,0l-0.03,0l-4.51-7.82L15.04,13.65z M10.97,12.88l1.81-3.14l1.81,3.14L10.97,12.88z M20.55,21.66L20.55,21.66L20.55,21.66
                        l-4.62-8.02l-0.33-0.57c0,0,0,0,0,0l-2.48-4.29c-0.07-0.12-0.2-0.19-0.33-0.19h0c-0.14,0-0.27,0.07-0.33,0.19l-2.48,4.29
                        c-0.07,0.12-0.07,0.27,0,0.39l4.84,8.4l-2.03,3.52l-4.62-8.01c-0.07-0.12-0.2-0.19-0.33-0.19c0,0,0,0,0,0
                        c-0.14,0-0.27,0.07-0.33,0.19l-4.63,8.01l-2.03-3.52L12.79,1.16l6.19,10.73l5.75,9.96l-2.03,3.52L20.55,21.66z M27.43,25.76l-4.07,0
                        l2.04-3.52l4.07,0L27.43,25.76z"/>
                    <path class="st0" d="M35.09,2.53h6.27v1.65h-4.31v2.06h3.73v1.52h-3.73v3.68h-1.96V2.53z"/>
                    <path class="st0" d="M41.92,6.98c0.1-1.66,1.59-2.16,3.03-2.16c1.29,0,2.83,0.29,2.83,1.84v3.36c0,0.59,0.06,1.17,0.22,1.44h-1.8
                        c-0.06-0.2-0.11-0.41-0.13-0.62c-0.56,0.59-1.39,0.8-2.17,0.8c-1.22,0-2.2-0.61-2.2-1.94c0-1.46,1.1-1.81,2.2-1.96
                        c1.09-0.16,2.1-0.13,2.1-0.85c0-0.76-0.53-0.87-1.15-0.87c-0.67,0-1.11,0.27-1.17,0.97H41.92z M46.02,8.29
                        c-0.3,0.26-0.92,0.27-1.47,0.37c-0.55,0.11-1.05,0.3-1.05,0.95c0,0.66,0.51,0.82,1.09,0.82c1.39,0,1.44-1.1,1.44-1.49V8.29z"/>
                    <path class="st0" d="M53.25,7.27c-0.11-0.72-0.57-1.11-1.31-1.11c-1.14,0-1.51,1.15-1.51,2.1c0,0.92,0.36,2.04,1.47,2.04
                        c0.82,0,1.3-0.52,1.41-1.31h1.71c-0.22,1.71-1.41,2.65-3.11,2.65c-1.95,0-3.26-1.37-3.26-3.31c0-2.01,1.2-3.5,3.3-3.5
                        c1.52,0,2.92,0.8,3.04,2.45H53.25z"/>
                    <path class="st0" d="M57.96,4.99h1.3v1.19h-1.3v3.2c0,0.6,0.15,0.75,0.75,0.75c0.19,0,0.36-0.01,0.55-0.05v1.39
                        c-0.3,0.05-0.69,0.06-1.04,0.06c-1.09,0-2.04-0.25-2.04-1.54V6.18h-1.07V4.99h1.07V3.06h1.77V4.99z"/>
                    <path class="st0" d="M62.93,4.82c2.04,0,3.35,1.35,3.35,3.41c0,2.05-1.31,3.4-3.35,3.4c-2.02,0-3.33-1.35-3.33-3.4
                        C59.59,6.17,60.91,4.82,62.93,4.82z M62.93,10.29c1.21,0,1.57-1.04,1.57-2.06c0-1.04-0.36-2.07-1.57-2.07
                        c-1.2,0-1.56,1.04-1.56,2.07C61.37,9.25,61.73,10.29,62.93,10.29z"/>
                    <path class="st0" d="M67.11,4.99h1.69v1.2h0.02c0.32-0.81,1.2-1.37,2.06-1.37c0.13,0,0.27,0.02,0.39,0.06v1.65
                        c-0.16-0.04-0.42-0.06-0.64-0.06c-1.3,0-1.75,0.94-1.75,2.07v2.91h-1.77V4.99z"/>
                    <path class="st0" d="M72.9,4.19h-1.24l1.2-1.77h1.96L72.9,4.19z M71.71,4.99h1.77v6.46h-1.77V4.99z"/>
                    <path class="st0" d="M74.5,6.98c0.1-1.66,1.59-2.16,3.04-2.16c1.29,0,2.83,0.29,2.83,1.84v3.36c0,0.59,0.06,1.17,0.22,1.44h-1.8
                        c-0.06-0.2-0.11-0.41-0.12-0.62c-0.56,0.59-1.39,0.8-2.17,0.8c-1.22,0-2.2-0.61-2.2-1.94c0-1.46,1.1-1.81,2.2-1.96
                        c1.09-0.16,2.1-0.13,2.1-0.85c0-0.76-0.52-0.87-1.15-0.87c-0.67,0-1.11,0.27-1.17,0.97H74.5z M78.59,8.29
                        c-0.3,0.26-0.92,0.27-1.47,0.37c-0.55,0.11-1.05,0.3-1.05,0.95c0,0.66,0.51,0.82,1.09,0.82c1.39,0,1.44-1.1,1.44-1.49V8.29z"/>
                    <path class="st0" d="M38.47,15.02h2.01l3.33,8.92h-2.04l-0.67-1.99h-3.33l-0.7,1.99h-1.99L38.47,15.02z M38.29,20.49h2.31
                        l-1.12-3.27h-0.03L38.29,20.49z"/>
                    <path class="st0" d="M50.15,23.94h-1.69v-0.9h-0.04c-0.45,0.72-1.22,1.07-1.97,1.07c-1.89,0-2.36-1.06-2.36-2.66v-3.97h1.77v3.65
                        c0,1.06,0.31,1.59,1.14,1.59c0.96,0,1.37-0.54,1.37-1.85v-3.38h1.77V23.94z"/>
                    <path class="st0" d="M55.8,23.12h-0.03c-0.41,0.7-1.14,1-1.96,1c-1.95,0-2.9-1.67-2.9-3.46c0-1.74,0.96-3.35,2.86-3.35
                        c0.76,0,1.51,0.32,1.91,0.96h0.03v-3.25h1.77v8.92H55.8V23.12z M54.25,18.64c-1.16,0-1.56,1-1.56,2.06c0,1.01,0.46,2.07,1.56,2.07
                        c1.17,0,1.52-1.02,1.52-2.09C55.77,19.64,55.4,18.64,54.25,18.64z"/>
                    <path class="st0" d="M60.33,16.48h-1.77v-1.46h1.77V16.48z M58.56,17.48h1.77v6.46h-1.77V17.48z"/>
                    <path class="st0" d="M64.55,17.31c2.04,0,3.35,1.35,3.35,3.41c0,2.05-1.31,3.4-3.35,3.4c-2.02,0-3.33-1.35-3.33-3.4
                        C61.22,18.66,62.53,17.31,64.55,17.31z M64.55,22.78c1.21,0,1.57-1.04,1.57-2.06c0-1.04-0.36-2.07-1.57-2.07
                        c-1.2,0-1.56,1.04-1.56,2.07C62.99,21.74,63.35,22.78,64.55,22.78z"/>
                    <path class="st0" d="M72.31,23.94h-1.97l-2.21-6.46h1.86l1.36,4.41h0.02l1.36-4.41h1.76L72.31,23.94z"/>
                    <path class="st0" d="M76.74,16.48h-1.77v-1.46h1.77V16.48z M74.97,17.48h1.77v6.46h-1.77V17.48z"/>
                    <path class="st0" d="M79.2,21.84c0.01,0.77,0.66,1.09,1.36,1.09c0.51,0,1.16-0.2,1.16-0.82c0-0.54-0.74-0.72-2.01-1
                        c-1.02-0.22-2.05-0.59-2.05-1.72c0-1.65,1.42-2.07,2.81-2.07c1.41,0,2.71,0.47,2.85,2.06h-1.69c-0.05-0.69-0.57-0.87-1.21-0.87
                        c-0.4,0-0.99,0.08-0.99,0.6c0,0.64,1,0.72,2.01,0.96c1.04,0.24,2.05,0.61,2.05,1.81c0,1.7-1.47,2.25-2.95,2.25
                        c-1.5,0-2.96-0.56-3.04-2.27H79.2z"/>
                    <path class="st0" d="M90.28,23.94H88.6v-0.9h-0.04c-0.45,0.72-1.22,1.07-1.97,1.07c-1.89,0-2.36-1.06-2.36-2.66v-3.97H86v3.65
                        c0,1.06,0.31,1.59,1.14,1.59c0.96,0,1.37-0.54,1.37-1.85v-3.38h1.77V23.94z"/>
                    <path class="st0" d="M91.24,19.47c0.1-1.66,1.59-2.16,3.04-2.16c1.29,0,2.83,0.29,2.83,1.84v3.36c0,0.59,0.06,1.17,0.22,1.44h-1.8
                        c-0.06-0.2-0.11-0.41-0.12-0.62c-0.56,0.59-1.39,0.8-2.17,0.8c-1.22,0-2.2-0.61-2.2-1.94c0-1.46,1.1-1.81,2.2-1.96
                        c1.09-0.16,2.1-0.13,2.1-0.85c0-0.76-0.52-0.87-1.15-0.87c-0.67,0-1.11,0.27-1.17,0.97H91.24z M95.34,20.78
                        c-0.3,0.26-0.92,0.27-1.47,0.37c-0.55,0.11-1.05,0.3-1.05,0.95c0,0.66,0.51,0.82,1.09,0.82c1.39,0,1.44-1.1,1.44-1.49V20.78z"/>
                    <path class="st0" d="M98.23,15.02H100v8.92h-1.77V15.02z"/>
                    </svg>
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>