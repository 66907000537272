<template>
    <header class="artifact__header">
        <div class="artifact__header__container">
            <div class="branding">
                <a href="/">
                    <svg version="1.0" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 402.52 99.21" style="enable-background:new 0 0 402.52 99.21;" xml:space="preserve">
                    <path class="st0" d="M33.24,53.29V4.23c0-2.16,1.6-4.02,3.64-4.21C37.01,0.01,37.15,0,37.28,0c2.23,0,4.04,1.81,4.04,4.04v49.25
                        H33.24z"/>
                    <path class="st0" d="M49.53,99.21c-10.2,0-16.29-6.47-16.29-17.31V70.57h8.08v10.68c0,7.38,2.38,10.38,8.21,10.38
                        c3.59,0,8.36-1.08,8.36-10.38V45.92h8.08V81.9C65.97,92.74,59.83,99.21,49.53,99.21z"/>
                    <path class="st0" d="M57.89,28.64V4.04C57.89,1.81,59.7,0,61.93,0c2.23,0,4.04,1.81,4.04,4.04v24.6H57.89z"/>
                    <path class="st0" d="M45.92,41.32v-8.08h49.25c2.23,0,4.04,1.81,4.04,4.04c0,2.23-1.81,4.04-4.04,4.04H45.92z"/>
                    <path class="st0" d="M17.31,65.97C6.47,65.97,0,59.83,0,49.53c0-10.2,6.47-16.29,17.31-16.29h11.33v8.08H17.96
                        c-7.38,0-10.38,2.38-10.38,8.21c0,3.59,1.08,8.36,10.38,8.36h35.33v8.08H17.31z"/>
                    <path class="st0" d="M70.57,65.97v-8.08h24.6c2.23,0,4.04,1.81,4.04,4.04c0,2.23-1.81,4.04-4.04,4.04H70.57z"/>
                    <path class="st1" d="M125.71,13.48c0.43,0,0.89,0.12,1.36,0.35c0.48,0.23,0.84,0.53,1.09,0.9l10.07,15.5l10.38-15.5
                        c0.59-0.83,1.34-1.25,2.27-1.25c0.74,0,1.4,0.26,1.99,0.79c0.59,0.52,0.88,1.2,0.88,2.04v26.78c0,0.8-0.26,1.47-0.79,2.01
                        c-0.53,0.54-1.22,0.81-2.08,0.81c-0.86,0-1.57-0.27-2.1-0.81c-0.54-0.54-0.81-1.21-0.81-2.01V24.46l-7.63,11.64
                        c-0.28,0.34-0.62,0.63-1.04,0.88c-0.42,0.25-0.84,0.36-1.27,0.32c-0.4,0.03-0.81-0.08-1.23-0.32c-0.42-0.25-0.76-0.54-1.04-0.88
                        l-7.63-12.07l0,19.05c0,0.8-0.25,1.47-0.74,2.01c-0.49,0.54-1.14,0.81-1.94,0.81c-0.77,0-1.4-0.27-1.9-0.81
                        c-0.49-0.54-0.74-1.21-0.74-2.01V16.3c0-0.77,0.28-1.43,0.86-1.99C124.22,13.76,124.91,13.48,125.71,13.48z"/>
                    <path class="st1" d="M162.62,18.15c-1.05,0-1.79-0.17-2.22-0.51c-0.43-0.34-0.65-0.94-0.65-1.8v-0.88c0-0.89,0.24-1.5,0.72-1.83
                        c0.48-0.32,1.21-0.49,2.2-0.49c1.08,0,1.83,0.17,2.27,0.51c0.43,0.34,0.65,0.94,0.65,1.8v0.88c0,0.89-0.23,1.5-0.69,1.83
                        C164.43,17.99,163.67,18.15,162.62,18.15z"/>
                    <path class="st1" d="M165.45,43.08c0,0.8-0.26,1.47-0.79,2.01c-0.53,0.54-1.19,0.81-1.99,0.81c-0.8,0-1.47-0.27-1.99-0.81
                        c-0.53-0.54-0.79-1.21-0.79-2.01v-19.1c0-0.8,0.26-1.47,0.79-2.01c0.52-0.54,1.19-0.81,1.99-0.81c0.8,0,1.46,0.27,1.99,0.81
                        c0.52,0.54,0.79,1.21,0.79,2.01V43.08z"/>
                    <path class="st1" d="M202.45,21.16c0.8,0,1.46,0.27,1.99,0.81c0.52,0.54,0.79,1.21,0.79,2.01v11.75c0,3.27-0.91,5.86-2.73,7.77
                        c-1.82,1.91-4.44,2.87-7.86,2.87c-3.42,0-6.04-0.96-7.84-2.87c-1.8-1.91-2.71-4.5-2.71-7.77V23.98c0-0.8,0.26-1.47,0.79-2.01
                        c0.52-0.54,1.19-0.81,1.99-0.81c0.8,0,1.46,0.27,1.99,0.81c0.52,0.54,0.79,1.21,0.79,2.01v11.75c0,1.88,0.42,3.28,1.25,4.19
                        c0.83,0.91,2.08,1.36,3.75,1.36c1.7,0,2.96-0.45,3.79-1.36c0.83-0.91,1.25-2.3,1.25-4.19V23.98c0-0.8,0.26-1.47,0.79-2.01
                        C200.99,21.43,201.65,21.16,202.45,21.16z"/>
                    <path class="st1" d="M214.02,45.91c-0.8,0-1.47-0.27-1.99-0.81c-0.53-0.54-0.79-1.21-0.79-2.01v-19.1c0-0.8,0.26-1.47,0.79-2.01
                        c0.52-0.54,1.19-0.81,1.99-0.81c0.8,0,1.46,0.27,1.99,0.81c0.52,0.54,0.79,1.21,0.79,2.01v0.62c0,0,0.45-0.77,0.99-1.34
                        c0.54-0.57,1.15-1.05,1.83-1.43c0.68-0.39,1.4-0.67,2.17-0.86s1.54-0.28,2.31-0.28c0.93,0,1.7,0.26,2.34,0.79
                        c0.63,0.52,0.95,1.14,0.95,1.85c0,1.02-0.26,1.75-0.79,2.2c-0.52,0.45-1.09,0.67-1.71,0.67c-0.59,0-1.12-0.11-1.6-0.32
                        c-0.48-0.22-1.03-0.32-1.64-0.32c-0.55,0-1.12,0.13-1.69,0.39c-0.57,0.26-1.09,0.67-1.57,1.23c-0.48,0.55-0.86,1.24-1.16,2.06
                        c-0.29,0.82-0.44,1.78-0.44,2.89v10.96c0,0.8-0.26,1.47-0.79,2.01C215.48,45.64,214.82,45.91,214.02,45.91z"/>
                    <path class="st1" d="M253.41,26.87c-1.13-1.93-2.64-3.44-4.53-4.53c-1.9-1.09-4.02-1.64-6.36-1.64c-2.34,0-4.46,0.55-6.36,1.64
                        c-1.9,1.1-3.41,2.61-4.53,4.53c-1.13,1.93-1.69,4.16-1.69,6.68c0,2.53,0.56,4.76,1.69,6.68c1.13,1.93,2.64,3.43,4.53,4.51
                        c1.9,1.08,4.02,1.62,6.36,1.62c2.34,0,4.46-0.54,6.36-1.62c1.9-1.08,3.41-2.58,4.53-4.51c1.13-1.93,1.69-4.16,1.69-6.68
                        C255.09,31.03,254.53,28.8,253.41,26.87z M248.59,37.6c-0.63,1.16-1.48,2.06-2.54,2.71c-1.06,0.65-2.24,0.97-3.54,0.97
                        c-1.3,0-2.47-0.32-3.54-0.97c-1.06-0.65-1.91-1.55-2.54-2.71c-0.63-1.16-0.95-2.51-0.95-4.05c0-1.57,0.32-2.94,0.95-4.09
                        c0.63-1.16,1.48-2.06,2.54-2.71c1.06-0.65,2.24-0.97,3.54-0.97c1.3,0,2.47,0.32,3.54,0.97c1.06,0.65,1.91,1.55,2.54,2.71
                        c0.63,1.16,0.95,2.52,0.95,4.09C249.54,35.1,249.23,36.45,248.59,37.6z"/>
                    <path class="st1" d="M242.74,19.58c-0.25,0-0.56-0.13-0.95-0.39c-0.39-0.26-0.56-0.64-0.53-1.13c0-0.28,0.09-0.57,0.28-0.88
                        l2.68-4.72c0.28-0.49,0.63-0.93,1.06-1.3c0.43-0.37,0.93-0.56,1.48-0.56c0.86,0,1.5,0.22,1.9,0.67c0.4,0.45,0.59,0.99,0.55,1.64
                        c-0.03,0.31-0.12,0.63-0.25,0.97c-0.14,0.34-0.35,0.68-0.62,1.02l-3.52,3.89c-0.31,0.34-0.65,0.56-1.02,0.65
                        C243.44,19.54,243.08,19.58,242.74,19.58z"/>
                    <path class="st1" d="M265.73,43.08c0,0.8-0.27,1.47-0.81,2.01c-0.54,0.54-1.21,0.81-2.01,0.81c-0.77,0-1.42-0.27-1.94-0.81
                        c-0.53-0.54-0.79-1.21-0.79-2.01V14.5c0-0.8,0.27-1.47,0.81-2.01c0.54-0.54,1.21-0.81,2.01-0.81c0.8,0,1.46,0.27,1.97,0.81
                        c0.51,0.54,0.76,1.21,0.76,2.01V43.08z"/>
                    <path class="st1" d="M295.52,33.55c0,2.53-0.56,4.76-1.69,6.68c-1.13,1.93-2.64,3.43-4.53,4.51c-1.9,1.08-4.02,1.62-6.36,1.62
                        c-2.34,0-4.46-0.54-6.36-1.62c-1.9-1.08-3.41-2.58-4.53-4.51c-1.13-1.93-1.69-4.16-1.69-6.68c0-2.53,0.56-4.76,1.69-6.68
                        c1.13-1.93,2.64-3.44,4.53-4.53c1.9-1.09,4.02-1.64,6.36-1.64c2.34,0,4.46,0.55,6.36,1.64c1.9,1.09,3.41,2.61,4.53,4.53
                        C294.96,28.8,295.52,31.03,295.52,33.55z M289.97,33.55c0-1.57-0.32-2.94-0.95-4.09c-0.63-1.16-1.48-2.06-2.54-2.71
                        c-1.06-0.65-2.24-0.97-3.54-0.97s-2.47,0.32-3.54,0.97c-1.06,0.65-1.91,1.55-2.54,2.71c-0.63,1.16-0.95,2.52-0.95,4.09
                        c0,1.54,0.32,2.89,0.95,4.05c0.63,1.16,1.48,2.06,2.54,2.71c1.06,0.65,2.24,0.97,3.54,0.97s2.47-0.32,3.54-0.97
                        c1.06-0.65,1.91-1.55,2.54-2.71C289.66,36.45,289.97,35.1,289.97,33.55z"/>
                    <path class="st1" d="M322.63,21.48c-0.52-0.52-1.19-0.79-1.99-0.79c-0.8,0-1.47,0.27-1.99,0.81c-0.52,0.54-0.79,1.21-0.79,2.01
                        l0,0.52c-0.37-0.41-0.81-0.81-1.32-1.21c-0.79-0.62-1.72-1.13-2.8-1.53c-1.08-0.4-2.27-0.6-3.56-0.6c-2.13,0-4.05,0.55-5.78,1.64
                        c-1.73,1.1-3.1,2.61-4.12,4.53c-1.02,1.93-1.53,4.16-1.53,6.68c0,2.5,0.51,4.71,1.53,6.64c1.02,1.93,2.41,3.44,4.16,4.53
                        c1.76,1.09,3.73,1.64,5.92,1.64c1.11,0,2.16-0.19,3.15-0.58c0.99-0.38,1.84-0.86,2.57-1.41c0.72-0.55,1.3-1.12,1.71-1.69
                        c0.03-0.04,0.05-0.07,0.07-0.1v2.25c0,1.63-0.56,2.91-1.67,3.84c-1.11,0.93-2.82,1.39-5.14,1.39c-0.65,0-1.4-0.12-2.24-0.35
                        c-0.85-0.23-1.63-0.49-2.34-0.76c-0.71-0.28-1.22-0.46-1.53-0.56c-0.68-0.25-1.33-0.21-1.94,0.12c-0.62,0.32-1.06,0.93-1.34,1.83
                        c-0.22,0.68-0.15,1.32,0.21,1.92c0.35,0.6,0.99,1.1,1.92,1.5c0.46,0.19,1.14,0.39,2.04,0.6c0.89,0.22,1.83,0.4,2.82,0.56
                        c0.99,0.15,1.79,0.23,2.41,0.23c2.16,0,4.18-0.36,6.06-1.09c1.88-0.73,3.4-1.9,4.56-3.52c1.16-1.62,1.73-3.77,1.73-6.45V23.52
                        C323.42,22.68,323.16,22.01,322.63,21.48z M317.22,37.49c-0.59,1.17-1.4,2.1-2.45,2.78c-1.05,0.68-2.28,1.02-3.7,1.02
                        c-1.39,0-2.61-0.34-3.65-1.02c-1.05-0.68-1.87-1.6-2.45-2.78c-0.59-1.17-0.88-2.48-0.88-3.93c0-1.48,0.29-2.81,0.88-3.98
                        c0.59-1.17,1.4-2.1,2.45-2.78c1.05-0.68,2.27-1.02,3.65-1.02c1.42,0,2.65,0.34,3.7,1.02c1.05,0.68,1.86,1.6,2.45,2.78
                        c0.59,1.17,0.88,2.5,0.88,3.98C318.1,35,317.8,36.31,317.22,37.49z"/>
                    <path class="st1" d="M352.28,33.55c0,2.53-0.56,4.76-1.69,6.68c-1.13,1.93-2.64,3.43-4.53,4.51c-1.9,1.08-4.02,1.62-6.36,1.62
                        c-2.34,0-4.46-0.54-6.36-1.62c-1.9-1.08-3.41-2.58-4.53-4.51c-1.13-1.93-1.69-4.16-1.69-6.68c0-2.53,0.56-4.76,1.69-6.68
                        c1.13-1.93,2.64-3.44,4.53-4.53c1.9-1.09,4.02-1.64,6.36-1.64c2.34,0,4.46,0.55,6.36,1.64c1.9,1.09,3.41,2.61,4.53,4.53
                        C351.72,28.8,352.28,31.03,352.28,33.55z M346.73,33.55c0-1.57-0.32-2.94-0.95-4.09c-0.63-1.16-1.48-2.06-2.54-2.71
                        c-1.06-0.65-2.24-0.97-3.54-0.97c-1.3,0-2.47,0.32-3.54,0.97c-1.06,0.65-1.91,1.55-2.54,2.71c-0.63,1.16-0.95,2.52-0.95,4.09
                        c0,1.54,0.32,2.89,0.95,4.05c0.63,1.16,1.48,2.06,2.54,2.71c1.06,0.65,2.24,0.97,3.54,0.97c1.3,0,2.47-0.32,3.54-0.97
                        c1.06-0.65,1.91-1.55,2.54-2.71C346.42,36.45,346.73,35.1,346.73,33.55z"/>
                    <path class="st1" d="M144.35,54.7c-0.52-0.52-1.19-0.79-1.99-0.79c-0.8,0-1.47,0.27-1.99,0.81c-0.52,0.54-0.79,1.21-0.79,2.01v9.08
                        c-0.27-0.25-0.57-0.5-0.9-0.75c-0.82-0.62-1.78-1.13-2.89-1.53c-1.11-0.4-2.33-0.6-3.65-0.6c-2.07,0-3.95,0.55-5.64,1.64
                        c-1.7,1.09-3.05,2.61-4.05,4.53c-1,1.93-1.5,4.16-1.5,6.68c0,2.5,0.5,4.71,1.5,6.64c1,1.93,2.37,3.44,4.09,4.53
                        c1.73,1.09,3.65,1.64,5.78,1.64c1.17,0,2.26-0.19,3.26-0.58c1-0.38,1.87-0.86,2.61-1.41c0.55-0.41,1.01-0.83,1.39-1.25
                        c0.01,0.78,0.27,1.44,0.78,1.97c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V56.73
                        C145.14,55.9,144.88,55.22,144.35,54.7z M138.96,79.72c-0.57,1.17-1.37,2.1-2.38,2.78s-2.21,1.02-3.56,1.02
                        c-1.33,0-2.5-0.34-3.52-1.02c-1.02-0.68-1.81-1.6-2.38-2.78c-0.57-1.17-0.86-2.48-0.86-3.93c0-1.48,0.28-2.81,0.86-3.98
                        c0.57-1.17,1.36-2.1,2.38-2.78c1.02-0.68,2.19-1.02,3.52-1.02c1.36,0,2.54,0.34,3.56,1.02c1.02,0.68,1.81,1.6,2.38,2.78
                        c0.57,1.17,0.86,2.5,0.86,3.98C139.82,77.24,139.53,78.55,138.96,79.72z"/>
                    <path class="st1" d="M172.48,76.99c0.55-0.46,0.85-1.06,0.88-1.8c0-1.67-0.32-3.24-0.95-4.72c-0.63-1.48-1.49-2.78-2.57-3.91
                        c-1.08-1.13-2.32-2.01-3.72-2.66c-1.4-0.65-2.89-0.97-4.46-0.97c-2.03,0-3.96,0.52-5.78,1.57c-1.82,1.05-3.29,2.55-4.42,4.51
                        c-1.13,1.96-1.69,4.32-1.69,7.1c0,2.38,0.52,4.5,1.55,6.38c1.03,1.88,2.51,3.37,4.44,4.46c1.93,1.09,4.2,1.64,6.82,1.64
                        c1.54,0,3.04-0.25,4.49-0.76c1.45-0.51,2.57-1.1,3.38-1.78c0.86-0.68,1.3-1.43,1.3-2.27c0-0.62-0.22-1.16-0.67-1.62
                        c-0.45-0.46-1.01-0.69-1.69-0.69c-0.49,0-1.02,0.14-1.57,0.42c-0.34,0.19-0.72,0.41-1.13,0.67c-0.42,0.26-0.96,0.49-1.64,0.67
                        c-0.68,0.19-1.59,0.28-2.73,0.28c-1.23,0-2.4-0.3-3.49-0.9c-1.1-0.6-1.99-1.48-2.68-2.64c-0.4-0.67-0.68-1.44-0.84-2.29h15.25
                        C171.27,77.69,171.92,77.45,172.48,76.99z M167.44,73.06h-12.11c0.07-0.38,0.16-0.74,0.27-1.06c0.34-0.99,0.8-1.77,1.39-2.36
                        c0.59-0.59,1.28-1,2.08-1.25c0.8-0.25,1.66-0.37,2.59-0.37c0.96,0,1.86,0.21,2.71,0.62c0.85,0.42,1.56,0.98,2.13,1.69
                        c0.57,0.71,0.89,1.51,0.95,2.41V73.06z"/>
                    <path class="st1" d="M201.71,62.93c1.76,0,3.29,0.18,4.6,0.55c1.31,0.37,2.33,0.89,3.05,1.55c0.72,0.66,1.09,1.46,1.09,2.38
                        c0,0.62-0.19,1.2-0.56,1.73c-0.37,0.54-0.91,0.81-1.62,0.81c-0.49,0-0.9-0.07-1.23-0.21c-0.32-0.14-0.61-0.32-0.86-0.53
                        c-0.25-0.22-0.54-0.42-0.88-0.6c-0.31-0.18-0.78-0.33-1.41-0.44c-0.63-0.11-1.1-0.16-1.41-0.16c-1.57,0-2.91,0.34-4,1.02
                        c-1.09,0.68-1.94,1.6-2.52,2.75c-0.59,1.16-0.88,2.49-0.88,4c0,1.48,0.3,2.8,0.9,3.96c0.6,1.16,1.43,2.07,2.5,2.75
                        c1.06,0.68,2.29,1.02,3.68,1.02c0.77,0,1.43-0.05,1.99-0.14c0.55-0.09,1.02-0.23,1.39-0.42c0.43-0.25,0.82-0.51,1.16-0.79
                        c0.34-0.28,0.85-0.42,1.53-0.42c0.8,0,1.42,0.25,1.85,0.76c0.43,0.51,0.65,1.13,0.65,1.87c0,0.77-0.43,1.47-1.3,2.1
                        c-0.86,0.63-2,1.14-3.4,1.53c-1.4,0.39-2.92,0.58-4.56,0.58c-2.44,0-4.55-0.56-6.34-1.69c-1.79-1.13-3.17-2.66-4.14-4.6
                        c-0.97-1.94-1.46-4.12-1.46-6.52c0-2.53,0.52-4.76,1.55-6.68c1.03-1.93,2.47-3.44,4.3-4.53C197.23,63.48,199.34,62.93,201.71,62.93z
                        "/>
                    <path class="st1" d="M236.85,69.11c-1.13-1.93-2.64-3.44-4.53-4.53c-1.9-1.09-4.02-1.64-6.36-1.64c-2.34,0-4.46,0.55-6.36,1.64
                        c-1.9,1.09-3.41,2.61-4.53,4.53c-1.13,1.93-1.69,4.16-1.69,6.68c0,2.53,0.56,4.76,1.69,6.68c1.13,1.93,2.64,3.43,4.53,4.51
                        c1.9,1.08,4.02,1.62,6.36,1.62c2.34,0,4.46-0.54,6.36-1.62c1.9-1.08,3.41-2.58,4.53-4.51c1.13-1.93,1.69-4.16,1.69-6.68
                        C238.53,73.26,237.97,71.03,236.85,69.11z M232.03,79.84c-0.63,1.16-1.48,2.06-2.54,2.71c-1.06,0.65-2.24,0.97-3.54,0.97
                        c-1.3,0-2.47-0.32-3.54-0.97c-1.06-0.65-1.91-1.55-2.54-2.71c-0.63-1.16-0.95-2.51-0.95-4.05c0-1.57,0.32-2.94,0.95-4.09
                        c0.63-1.16,1.48-2.06,2.54-2.71c1.06-0.65,2.24-0.97,3.54-0.97c1.3,0,2.47,0.32,3.54,0.97c1.06,0.65,1.91,1.55,2.54,2.71
                        c0.63,1.16,0.95,2.52,0.95,4.09C232.98,77.33,232.67,78.68,232.03,79.84z"/>
                    <path class="st1" d="M264.05,68.18c-0.45-1.59-1.26-2.86-2.45-3.82c-1.19-0.96-2.92-1.43-5.2-1.43c-1.05,0-2.02,0.16-2.91,0.49
                        c-0.89,0.32-1.7,0.75-2.41,1.27c-0.71,0.52-1.33,1.09-1.85,1.69c-0.19,0.22-0.35,0.43-0.51,0.64v-0.8c0-0.8-0.26-1.47-0.79-2.01
                        c-0.52-0.54-1.19-0.81-1.99-0.81c-0.8,0-1.47,0.27-1.99,0.81c-0.53,0.54-0.79,1.21-0.79,2.01v19.1c0,0.8,0.26,1.47,0.79,2.01
                        c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V73.48c0-1.02,0.22-1.93,0.67-2.75
                        s1.1-1.47,1.94-1.97c0.85-0.49,1.89-0.74,3.12-0.74c1.26,0,2.24,0.25,2.91,0.74c0.68,0.49,1.15,1.15,1.41,1.97s0.39,1.73,0.39,2.75
                        v11.84c0,0.8,0.26,1.47,0.79,2.01c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V73.48
                        C264.72,71.53,264.49,69.77,264.05,68.18z"/>
                    <path class="st1" d="M279.98,60.5c0.15-0.45,0.44-0.84,0.86-1.18c0.42-0.34,1.01-0.51,1.78-0.51c0.52,0,1.06,0.07,1.62,0.21
                        s0.97,0.21,1.25,0.21c0.59,0,1.1-0.22,1.55-0.67c0.45-0.45,0.67-1.06,0.67-1.83c0-0.68-0.22-1.23-0.65-1.64
                        c-0.43-0.42-0.97-0.72-1.62-0.9c-0.65-0.19-1.31-0.28-1.99-0.28c-3.08,0-5.4,0.76-6.94,2.27c-1.54,1.51-2.31,3.36-2.31,5.55v2.13
                        h-2.5c-0.74,0-1.36,0.24-1.85,0.72c-0.49,0.48-0.74,1.09-0.74,1.83c0,0.74,0.25,1.35,0.74,1.83c0.49,0.48,1.11,0.72,1.85,0.72h2.5
                        v16.38c0,0.8,0.26,1.47,0.79,2.01c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V68.94
                        h4.21c0.74,0,1.36-0.24,1.85-0.72c0.49-0.48,0.74-1.09,0.74-1.83c0-0.74-0.25-1.35-0.74-1.83c-0.49-0.48-1.11-0.72-1.85-0.72h-4.21
                        v-2.17C279.75,61.34,279.83,60.95,279.98,60.5z"/>
                    <path class="st1" d="M292.43,60.39c-1.05,0-1.79-0.17-2.22-0.51c-0.43-0.34-0.65-0.94-0.65-1.8v-0.88c0-0.89,0.24-1.5,0.72-1.83
                        c0.48-0.32,1.21-0.49,2.2-0.49c1.08,0,1.83,0.17,2.27,0.51c0.43,0.34,0.65,0.94,0.65,1.8v0.88c0,0.89-0.23,1.5-0.69,1.83
                        C294.23,60.22,293.47,60.39,292.43,60.39z"/>
                    <path class="st1" d="M295.25,85.32c0,0.8-0.26,1.47-0.79,2.01c-0.52,0.54-1.19,0.81-1.99,0.81c-0.8,0-1.47-0.27-1.99-0.81
                        c-0.52-0.54-0.79-1.21-0.79-2.01v-19.1c0-0.8,0.26-1.47,0.79-2.01c0.52-0.54,1.19-0.81,1.99-0.81c0.8,0,1.46,0.27,1.99,0.81
                        c0.52,0.54,0.79,1.21,0.79,2.01V85.32z"/>
                    <path class="st1" d="M322.96,63.72c-0.52-0.52-1.19-0.79-1.99-0.79c-0.8,0-1.47,0.27-1.99,0.81c-0.52,0.54-0.79,1.21-0.79,2.01v0.07
                        c-0.27-0.26-0.57-0.51-0.9-0.76c-0.82-0.62-1.78-1.13-2.89-1.53c-1.11-0.4-2.33-0.6-3.65-0.6c-2.07,0-3.95,0.55-5.64,1.64
                        c-1.7,1.09-3.05,2.61-4.05,4.53c-1,1.93-1.5,4.16-1.5,6.68c0,2.5,0.5,4.71,1.5,6.64c1,1.93,2.37,3.44,4.09,4.53
                        c1.73,1.09,3.65,1.64,5.78,1.64c1.17,0,2.26-0.19,3.26-0.58c1-0.38,1.87-0.86,2.61-1.41c0.55-0.41,1.01-0.83,1.39-1.25
                        c0.01,0.78,0.27,1.44,0.78,1.97c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V65.75
                        C323.74,64.92,323.48,64.24,322.96,63.72z M317.57,79.72c-0.57,1.17-1.37,2.1-2.38,2.78c-1.02,0.68-2.21,1.02-3.56,1.02
                        c-1.33,0-2.5-0.34-3.52-1.02c-1.02-0.68-1.81-1.6-2.38-2.78c-0.57-1.17-0.86-2.48-0.86-3.93c0-1.48,0.28-2.81,0.86-3.98
                        c0.57-1.17,1.37-2.1,2.38-2.78c1.02-0.68,2.19-1.02,3.52-1.02c1.36,0,2.54,0.34,3.56,1.02c1.02,0.68,1.81,1.6,2.38,2.78
                        c0.57,1.17,0.86,2.5,0.86,3.98C318.42,77.24,318.14,78.55,317.57,79.72z"/>
                    <path class="st1" d="M350.64,68.18c-0.45-1.59-1.26-2.86-2.45-3.82c-1.19-0.96-2.92-1.43-5.2-1.43c-1.05,0-2.02,0.16-2.91,0.49
                        c-0.89,0.32-1.7,0.75-2.41,1.27c-0.71,0.52-1.33,1.09-1.85,1.69c-0.19,0.22-0.35,0.43-0.51,0.64v-0.8c0-0.8-0.26-1.47-0.79-2.01
                        c-0.52-0.54-1.19-0.81-1.99-0.81c-0.8,0-1.47,0.27-1.99,0.81c-0.53,0.54-0.79,1.21-0.79,2.01v19.1c0,0.8,0.26,1.47,0.79,2.01
                        c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V73.48c0-1.02,0.22-1.93,0.67-2.75
                        s1.09-1.47,1.94-1.97c0.85-0.49,1.89-0.74,3.12-0.74c1.26,0,2.24,0.25,2.91,0.74c0.68,0.49,1.15,1.15,1.41,1.97s0.39,1.73,0.39,2.75
                        v11.84c0,0.8,0.26,1.47,0.79,2.01c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V73.48
                        C351.31,71.53,351.09,69.77,350.64,68.18z"/>
                    <path class="st1" d="M374.95,83.77c-0.49-0.48-1.11-0.72-1.85-0.72h-9.69l11.12-14.39c0.34-0.43,0.55-0.8,0.62-1.11
                        s0.13-0.62,0.16-0.93c0.06-0.8-0.15-1.46-0.65-1.99c-0.49-0.52-1.09-0.79-1.8-0.79h-14.25c-0.74,0-1.36,0.24-1.85,0.72
                        c-0.49,0.48-0.74,1.07-0.74,1.78c0,0.74,0.25,1.36,0.74,1.85c0.49,0.49,1.11,0.74,1.85,0.74h8.98l-11.02,14.25
                        c-0.37,0.46-0.6,0.87-0.69,1.23c-0.09,0.36-0.16,0.76-0.19,1.23c-0.03,0.56,0.19,1.11,0.65,1.67c0.46,0.55,1.08,0.83,1.85,0.83
                        h14.89c0.74,0,1.36-0.25,1.85-0.74c0.49-0.49,0.74-1.09,0.74-1.8C375.69,84.86,375.44,84.25,374.95,83.77z"/>
                    <path class="st1" d="M401.73,63.72c-0.52-0.52-1.19-0.79-1.99-0.79c-0.8,0-1.47,0.27-1.99,0.81c-0.52,0.54-0.79,1.21-0.79,2.01v0.07
                        c-0.27-0.26-0.57-0.51-0.9-0.76c-0.82-0.62-1.78-1.13-2.89-1.53c-1.11-0.4-2.33-0.6-3.65-0.6c-2.07,0-3.95,0.55-5.64,1.64
                        c-1.7,1.09-3.05,2.61-4.05,4.53c-1,1.93-1.5,4.16-1.5,6.68c0,2.5,0.5,4.71,1.5,6.64c1,1.93,2.37,3.44,4.09,4.53
                        c1.73,1.09,3.65,1.64,5.78,1.64c1.17,0,2.26-0.19,3.26-0.58c1-0.38,1.87-0.86,2.61-1.41c0.55-0.41,1.01-0.83,1.39-1.25
                        c0.01,0.78,0.27,1.44,0.78,1.97c0.52,0.54,1.19,0.81,1.99,0.81c0.8,0,1.46-0.27,1.99-0.81c0.52-0.54,0.79-1.21,0.79-2.01V65.75
                        C402.52,64.92,402.26,64.24,401.73,63.72z M396.34,79.72c-0.57,1.17-1.37,2.1-2.38,2.78s-2.21,1.02-3.56,1.02
                        c-1.33,0-2.5-0.34-3.52-1.02s-1.81-1.6-2.38-2.78c-0.57-1.17-0.86-2.48-0.86-3.93c0-1.48,0.28-2.81,0.86-3.98
                        c0.57-1.17,1.37-2.1,2.38-2.78c1.02-0.68,2.19-1.02,3.52-1.02c1.36,0,2.54,0.34,3.56,1.02c1.02,0.68,1.81,1.6,2.38,2.78
                        c0.57,1.17,0.86,2.5,0.86,3.98C397.2,77.24,396.91,78.55,396.34,79.72z"/>
                    </svg>
                </a>
            </div>
            <nav class="artifact__controls">
                <router-link to="/" class="control__item" :class="{active: $route.name != 'Noticias'}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
                    <span>Buscador</span>
                </router-link>
                <router-link to="/noticias" class="control__item" :class="{active: $route.name == 'Noticias'}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 15h13v1h-13v-1zm4-4v3h5v-3h-5zm-1 0h-3v1h3v-1zm-3 3h3v-1h-3v1zm13-1h-3v1h3v-1zm-6.951-6.573v-.396h-1.215v1.941h1.255v-.396h-.78v-.406h.698v-.393h-.698v-.35h.74zm1.396.261l.238 1.284h.5l.501-1.941h-.482l-.249 1.32-.238-1.32h-.492l-.27 1.345-.24-1.345h-.505l.46 1.941h.506l.271-1.284zm1.901.916c-.149 0-.324-.043-.466-.116l-.024-.013-.098.398.015.008c.102.058.318.119.547.119.581 0 .788-.328.788-.61 0-.272-.161-.458-.507-.586-.254-.096-.338-.145-.338-.247 0-.098.1-.161.254-.161.136 0 .266.03.388.088l.023.011.107-.39-.015-.007c-.145-.065-.311-.098-.495-.098-.442 0-.739.239-.739.593 0 .262.181.458.535.581.227.081.304.144.304.247 0 .117-.102.183-.279.183zm-5.325.368h.485v-1.941h-.438v1.189l-.641-1.189h-.535v1.941h.438v-1.327l.691 1.327zm8.979 1.028h-13v1h13v-1zm0 2h-3v1h3v-1zm-17-9v17.199c0 .771-1 .771-1 0v-15.199h-2v15.98c0 1.115.905 2.02 2.02 2.02h19.958c1.117 0 2.022-.904 2.022-2.02v-17.98h-21zm19 16h-17v-14h17v14z"/></svg>
                    <span>Noticias</span>
                </router-link>
            </nav>
        </div>
        <div class="artifact__header__container">
            <div class="breadcrumbs">
                <!--<li>
                    <a href="/" class="breadcrumbs_item router-link-active">Home <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                    </a>
                </li>-->
                <li v-if="$parent.pais">
                    <router-link :to="`/buscador/${$parent.pais.url}`" class="breadcrumbs_item">{{$parent.pais.name}} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                    </router-link>
                </li>
                <li v-if="$parent.provincia">
                    <router-link :to="`/buscador/${$parent.pais.url}/${$parent.provincia.url}`" class="breadcrumbs_item">{{$parent.provincia.name}} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                    </router-link>
                </li>
                <li v-if="$parent.ciudad">
                    <router-link :to="`/buscador/${$parent.pais.url}/${$parent.provincia.url}/${$parent.ciudad.url}`" class="breadcrumbs_item">{{$parent.ciudad.name}} <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                    </router-link>
                </li>
                <li v-if="$parent.doctor"><span class="breadcrumbs_item">{{$parent.doctor.name}}</span>
                </li>
                <!--EN NOTICIA-->
                <li v-if="$route.name == 'Noticias'">
                    <router-link :to="`/noticias`" class="breadcrumbs_item">Noticias <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M13.025 1l-2.847 2.828 6.176 6.176h-16.354v3.992h16.354l-6.176 6.176 2.847 2.828 10.975-11z"></path></svg>
                    </router-link>
                </li>
                <li v-if="$parent.noticia"><span class="breadcrumbs_item">{{$parent.noticia.title}}</span>
                </li>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: 'Jeader'
}
</script>