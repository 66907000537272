<template>
  <div class="artifact__wrapper">
    <Jeader />
    <main class="artifact__container" :class="$route.name">
      <div
        class="main__component__container"
        :class="{ visible: $route.name != 'Noticias' }"
      >
        <div class="main__component">
          <div class="artifact__intro">
            <h2>Encuentra a tu Urólogo/Andrólogo más cercano a tí</h2>
            <h3 class="baloon_type">Puedes empezar eligiendo un país</h3>
          </div>
          <div class="component__nav">
            <div
              @click="lista == 1 ? (lista = 0) : (lista = 1)"
              class="component__nav_trigger"
              :class="{ open_list: lista == 1, chosen_item: pais }"
            >
              <div class="component__nav_marker">
                {{ pais ? pais.name : "País" }}
              </div>
            </div>
            <div
              @click="lista == 2 ? (lista = 0) : (lista = 2)"
              class="component__nav_trigger"
              :class="{
                disabled: !pais,
                open_list: lista == 2,
                chosen_item: provincia,
              }"
            >
              <div class="component__nav_marker">
                {{ provincia ? provincia.name : "Provincia" }}
              </div>
            </div>
            <div
              @click="lista == 3 ? (lista = 0) : (lista = 3)"
              class="component__nav_trigger"
              :class="{
                disabled: !provincia,
                open_list: lista == 3,
                chosen_item: ciudad,
              }"
            >
              <div class="component__nav_marker">
                {{ ciudad ? ciudad.name : "Ciudad" }}
              </div>
            </div>
          </div>
        </div>

        <div
          class="pub__component_small"
          v-if="anuncioFijo"
          :class="{ visible: claseAnuncioFijo }"
        >
          <a href="https://www.androgenital.com/" target="_blank">
            <img src="@/assets/images/banner_small.jpg" />
          </a>
        </div>

        <div class="component__sub__nav" :class="{ reveal: lista != 0 }">
          <div class="component__sub__nav_header">
            <h3 v-if="lista == 1">País</h3>
            <h3 v-else-if="lista == 2">Provincia</h3>
            <h3 v-else-if="lista == 3">Ciudad</h3>
            <div class="component__close_btn" @click="lista = 0">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"
                />
              </svg>
            </div>
          </div>
          <div class="component__sub__nav_data">
            <div class="loader black" :class="{ visible: loaderIzquierda }">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div class="component__nav_list">
              <ul>
                <li v-for="(select, index) in listaSelect" :key="index">
                  <div
                    class="component__nav_btn"
                    :class="{ selected: isSelected(select.id) }"
                    @click="seleccionar(select)"
                  >
                    {{ select.name }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="pub__component__container" :class="{ visible: anuncioPop }">
        <div class="pub__component">
          <div
            class="component__close_btn fixed right"
            @click="
              () => {
                anuncioPop = false;
                anuncioFijo = true;
              }
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"
              ></path>
            </svg>
          </div>
          <a
            href="https://www.androgenital.com/"
            target="_blank"
            class="banner_link"
          >
            <img src="@/assets/images/banner_main.jpg" />
          </a>
        </div>
      </div>

      <Mapa :key="doctores.length" :iframe="false" :doctors="doctores" />

      <div
        class="data__component__container"
        :class="{ visible: $route.name != 'Noticias' && showDerecha }"
      >
        <div class="data__component__header">
          <h2>Mi Urólogo de confianza:</h2>
          <!--<div class="component__close_btn" @click="showDerecha=false">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm5.5 16.084l-1.403 1.416-4.09-4.096-4.102 4.096-1.405-1.405 4.093-4.092-4.093-4.098 1.405-1.405 4.088 4.089 4.091-4.089 1.416 1.403-4.092 4.087 4.092 4.094z"/></svg>
                    </div>-->
        </div>
        <div class="data__component">
          <div class="loader white" :class="{ visible: loaderDerecha }">
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
          <transition name="infoWindow" mode="out-in">
            <Doctor v-if="doctor" :doctor="doctor" />
            <Doctores v-else-if="pais" :doctores="doctores" />
            <Intro v-else />
          </transition>
        </div>
      </div>

      <div class="news__container" :class="{ show: $route.name == 'Noticias' }">
        <transition name="infoWindow" mode="out-in">
          <Noticias v-if="noticias" :noticias="noticias" />
          <Noticia v-else-if="noticia" :noticia="noticia" />
        </transition>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import axios from "axios";
import Footer from "@/components/Footer.vue";
import Doctor from "@/components/Doctor.vue";
import Doctores from "@/components/Doctores.vue";
import Noticias from "@/components/Noticias.vue";
import Noticia from "@/components/Noticia.vue";
import Jeader from "@/components/Jeader.vue";
import Mapa from "@/components/Mapa.vue";
import Intro from "@/components/Intro.vue";

export default {
  name: "Busqueda",
  components: {
    Footer,
    Doctor,
    Doctores,
    Noticias,
    Noticia,
    Jeader,
    Mapa,
    Intro,
  },
  data: function () {
    return {
      listaSelect: [],

      pais: null,
      provincia: null,
      ciudad: null,

      lista: 0,

      doctores: [],
      doctor: null,

      noticias: null,
      noticia: null,

      loaderIzquierda: false,
      loaderDerecha: false,

      showDerecha: true,

      anuncioPop: false,
      anuncioFijo: false,
      claseAnuncioFijo: false,
    };
  },
  methods: {
    getPaises: function () {
      this.loader(1);
      axios
        .get(window.URL + "countries", {
          params: {
            specialists: 1,
            "orderBy[column]": "name",
            "orderBy[order]": "asc",
            trusted: 1,
            specialty: 1
          },
        })
        .then((response) => {
          let rs = response.data;
          if (rs.state == "OK") {
            this.listaSelect = rs.data;
            this.loaderIzquierda = false;
          }
        })
        .catch((e) => console.error(e));
    },
    getProvincias: function () {
      this.loader(1);
      axios
        .get(window.URL + "provinces", {
          params: {
            specialists: 1,
            country: this.pais.id,
            "orderBy[column]": "name",
            "orderBy[order]": "asc",
            trusted: 1,
            specialty: 1
          },
        })
        .then((response) => {
          let rs = response.data;
          if (rs.state == "OK") {
            this.listaSelect = rs.data;
            this.loaderIzquierda = false;
          }
        })
        .catch((e) => console.error(e));
    },
    getCiudades: function () {
      this.loader(1);
      axios
        .get(window.URL + "cities", {
          params: {
            specialists: 1,
            country: this.pais.id,
            province: this.provincia.id,
            "orderBy[column]": "name",
            "orderBy[order]": "asc",
            trusted: 1,
            specialty: 1
          },
        })
        .then((response) => {
          let rs = response.data;
          if (rs.state == "OK") {
            this.listaSelect = rs.data;
            this.loaderIzquierda = false;
          }
        })
        .catch((e) => console.error(e));
    },
    getPais: function (slug) {
      this.loader(1);
      axios
        .get(window.URL + `countries/${slug}`)
        .then((response) => {
          let rs = response.data;
          if (rs.state == "OK") this.pais = rs.data;
          this.provincia = null;
          this.ciudad = null;
        })
        .catch((e) => console.error(e));
    },
    getProvincia: function (slug) {
      this.loader(1);
      axios
        .get(window.URL + `provinces/${slug}`)
        .then((response) => {
          let rs = response.data;
          if (rs.state == "OK") this.provincia = rs.data;
          this.pais = this.provincia.country;
          this.ciudad = null;
        })
        .catch((e) => console.error(e));
    },
    getCiudad: function (slug) {
      this.loader(1);
      axios
        .get(window.URL + `cities/${slug}`)
        .then((response) => {
          let rs = response.data;
          if (rs.state == "OK") this.ciudad = rs.data;
          this.provincia = this.ciudad.province;
          this.pais = this.ciudad.country;
        })
        .catch((e) => console.error(e));
    },
    seleccionar: function (select) {
      this.doctor = null;
      this.noticia = null;
      this.noticias = null;
      switch (this.lista) {
        case 1:
          this.pais = select;
          this.$router.push(`/buscador/${select.url}`);
          this.provincia = null;
          this.ciudad = null;
          break;
        case 2:
          this.provincia = select;
          this.$router.push(`/buscador/${this.pais.url}/${select.url}`);
          this.ciudad = null;
          break;
        case 3:
          this.ciudad = select;
          this.$router.push(
            `/buscador/${this.pais.url}/${this.provincia.url}/${select.url}`
          );
          break;
      }
      this.lista = 0;
    },
    getDoctores: function () {
      this.showDerecha = true;
      this.loader(2);
      axios
        .get(window.URL + "specialists", {
          params: {
            country: this.pais ? this.pais.id : null,
            province: this.provincia ? this.provincia.id : null,
            city: this.ciudad ? this.ciudad.id : null,
            "orderBy[column]": "name",
            "orderBy[order]": "asc",
            active: 1,
            trusted: 1,
            specialty: 1
          },
        })
        .then((response) => {
          let rs = response.data;
          if (rs.state == "OK") {
            this.doctores = rs.data;
            this.doctor = null;
            this.loaderDerecha = false;
          }
        });
    },
    getDoctor: function (id) {
      this.loader(2);
      axios
        .get(window.URL + `specialists/${id}`, {
          params: {
            trusted: 1,
          },
        })
        .then((response) => {
          let rs = response.data;
          if (rs.state == "OK") {
            this.doctor = rs.data;
            this.doctores = [this.doctor];
            this.pais = this.doctor.country;
            this.provincia = this.doctor.province;
            this.ciudad = this.doctor.city;
            this.loaderDerecha = false;
          }
        });
    },
    getNoticias: function () {
      axios
        .get(window.URL + `notices`, {
          params: {
            trusted: 1,
            "orderBy[column]": "actived_at",
            "orderBy[order]": "desc",
            limit: 8,
            offset: this.noticias ? this.noticias.length : 0,
          },
        })
        .then((response) => {
          let rs = response.data;
          if (rs.state == "OK") {
            if (this.noticias && this.noticias.length > 0)
              this.noticias = this.noticias.concat(rs.data);
            else this.noticias = rs.data;
            this.noticia = null;
            this.doctor = null;
            this.pais = null;
            this.provincia = null;
            this.ciudad = null;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getNoticia: function (slug) {
      axios.get(window.URL + `notices/${slug}`).then((response) => {
        let rs = response.data;
        if (rs.state == "OK") {
          this.noticia = rs.data;
          this.noticias = null;
          this.doctor = null;
          this.pais = null;
          this.provincia = null;
          this.ciudad = null;
        }
      });
    },
    loader: function (sentido) {
      if (sentido == 1) {
        this.loaderIzquierda = true;
        setTimeout(() => {
          if (this.$route.params.ciudad) {
            if (this.ciudad) this.loaderIzquierda = false;
            return;
          } else if (this.$route.params.provincia) {
            if (this.provincia) this.loaderIzquierda = false;
            return;
          } else if (this.$route.params.pais) {
            if (this.pais) this.loaderIzquierda = false;
            return;
          }
        }, 1000);
      } else if (sentido == 2) {
        this.loaderDerecha = true;
        setTimeout(() => {
          if (this.$route.params.doctor)
            if (this.doctor) this.loaderDerecha = false;
            else if (this.doctores.length > 0) this.loaderDerecha = false;
        }, 1000);
      }
    },
    isSelected: function (id) {
      switch (this.lista) {
        case 1:
          if (this.pais && this.pais.id == id) return true;
          break;
        case 2:
          if (this.provincia && this.provincia.id == id) return true;
          break;
        case 3:
          if (this.ciudad && this.ciudad.id == id) return true;
          break;
      }
      return false;
    },
  },
  watch: {
    anuncioFijo: function () {
      setTimeout(() => {
        this.claseAnuncioFijo = true;
      }, 100);
    },
    lista: function () {
      switch (this.lista) {
        case 1:
          this.getPaises();
          break;
        case 2:
          this.getProvincias();
          break;
        case 3:
          this.getCiudades();
          break;
        case 0:
          this.listaSelect = [];
          break;
      }
    },
    pais: function () {
      if (!this.doctor) {
        this.getDoctores();
      }
      this.showDerecha = true;
    },
    provincia: function () {
      if (!this.doctor) this.getDoctores();
      this.showDerecha = true;
    },
    ciudad: function () {
      if (!this.doctor) this.getDoctores();
      this.showDerecha = true;
    },
    $route(to, from) {
      if (to.params.doctor) this.getDoctor(to.params.doctor);
      else if (to.name == "Noticias") {
        this.lista = 0;
        if (to.params.slug) this.getNoticia(to.params.slug);
        else this.getNoticias();
      }

      if (to.name != "Noticias") {
        this.noticias = null;
        this.noticia = null;
      }

      if (to.params.ciudad) {
        if (!this.ciudad) {
          this.getCiudad(to.params.ciudad);
        }
        this.doctor = null;
      } else if (to.params.provincia) {
        if (!this.provincia) {
          this.getProvincia(to.params.provincia);
        }
        this.doctor = null;
        this.ciudad = null;
      } else if (to.params.pais) {
        if (!this.pais) {
          this.getPais(to.params.pais);
        }
        this.doctor = null;
        this.provincia = null;
        this.ciudad = null;
      }
    },
  },
  created: function () {
    if (this.$route.params.doctor) this.getDoctor(this.$route.params.doctor);
    else if (this.$route.name == "Noticias") {
      if (this.$route.params.slug) this.getNoticia(this.$route.params.slug);
      else this.getNoticias();
    }

    if (this.$route.name != "Noticias") this.showDerecha = true;
    else this.showDerecha = false;

    if (this.$route.params.ciudad) {
      if (!this.ciudad) {
        this.getCiudad(this.$route.params.ciudad);
      }
    } else if (this.$route.params.provincia) {
      if (!this.provincia) {
        this.getProvincia(this.$route.params.provincia);
      }
    } else if (this.$route.params.pais) {
      if (!this.pais) {
        this.getPais(this.$route.params.pais);
      }
    }

    setTimeout(() => {
      this.anuncioPop = true;
    }, 30000);
  },
};
</script>

<style>
.infoWindow-enter-active,
.infoWindow-leave-active {
  transition: opacity 0.5s;
}
.infoWindow-enter,
.infoWindow-leave-to {
  opacity: 0;
}
</style>