<template>
    <div class="half_inner height_inner">
        <article class="content_block news">
            <div class="grid grid_wrap grid_pad">
                <div class="grid_col grid_col_1_1">
                    <div class="news_photo_container">
                        <img :src="noticia.image">
                    </div>
                    <div class="news_item_content">
                        <h5 class="news_date">{{$parent.$parent.formatDate('d/m/Y', noticia.actived_at)}}</h5>
                        <h2 class="news_heading">{{noticia.title}}</h2>
                        <div class="ql-editor" v-html="noticia.body">
                        </div>
                    </div>
                </div>    
            </div>
            <div class="btn small center white wide" @click="$router.push('/noticias')">Volver atrás</div>
        </article>
    </div>
</template>

<script>
export default {
    name: 'Noticia',
    props: {
        noticia: Object
    }
}
</script>